import { CircularProgress, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { convertToBrazilianTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'
import { approveSettleAmounts, getBalanceAmountsToSettle, accountDetails } from '../../../services/APIClient'
import Loading from '../../../components/Loading'

const columns = [
  'Merchant ID',
  'Approved by FX bank',
  'Balance date time',
  'Local currency amount',
  'Foreing currency amount',
]

const SettlementBatchToApprove = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isApproving, setIsApproving] = useState(false)
  const [amountsToSettleContent, setAmountsToSettleContent] = useState([])

  useEffect(() => {
    const getBalancesToSettle = async () => {
      const balancesToSettle = await getBalanceAmountsToSettle()
      const merchantIds = await Promise.all(balancesToSettle.map((balance) => accountDetails(balance.merchantId)))
      const fxBankByMerchantId = merchantIds.reduce((acc, item) => {
        acc[item.id] = item.fxBanksThatApprovedMerchant
        return acc
      }, {})
      const balancesContent = balancesToSettle.map((item) => {
        item.fxBanksThatApprovedMerchant = fxBankByMerchantId[item.merchantId]
        return item
      })
      setAmountsToSettleContent(balancesContent)
      setIsLoading(false)
    }

    getBalancesToSettle()
  }, [])

  const approveAmounts = async () => {
    setIsApproving(true)

    const balances = amountsToSettleContent.map((balance) => {
      return {
        merchantId: balance.merchantId,
        foreignCurrencyAmount: balance.foreignCurrencyAmount,
        localCurrencyAmount: balance.amount,
      }
    })

    await approveSettleAmounts({ merchantsAndAmounts: balances })
    setIsApproving(false)
  }

  const getTotalAmountInForeignCurrency = () => {
    return amountsToSettleContent.reduce((init, element) => init + element.foreignCurrencyAmount, 0)
  }

  const getTotalAmountInLocalCurrency = () => {
    return amountsToSettleContent.reduce((init, element) => init + element.amount, 0)
  }

  return (
    <div className="m-sm-30">
      <SimpleCard title="Settlement Batch B2B - To approve">
        {isLoading ? (
          <Loading />
        ) : amountsToSettleContent.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <button type="button" className="btn btn-success" onClick={() => approveAmounts()}>
              {isApproving ? <CircularProgress size={30} /> : 'Approve'}
            </button>
            <Table style={{ whiteSpace: 'pre' }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell className="px-0">{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {amountsToSettleContent.map((item) => (
                  <BalancesToApproveRow balance={item} />
                ))}
              </TableBody>
              <TableFooter>
                <div style={{ marginTop: 30 }}>
                  {`Total amount in local currency: ${currencyWithTwoDecimals(getTotalAmountInLocalCurrency(), '')} BRL`}
                </div>
                <div style={{ marginTop: 30 }}>
                  {`Total amount in foreign currency: ${currencyWithTwoDecimals(getTotalAmountInForeignCurrency(), '')} USD`}
                </div>
              </TableFooter>
            </Table>
          </div>
        ) : (
          <TableRow>
            <TableCell>There is no balance to settle</TableCell>
          </TableRow>
        )}
      </SimpleCard>
    </div>
  )
}

const BalancesToApproveRow = ({ balance }) => {
  let columnContent = [
    balance.merchantId,
    balance.fxBanksThatApprovedMerchant.map((item) => item.fxBank).join(', '),
    convertToBrazilianTimeZone(balance.balanceDateTime),
    `${currencyWithTwoDecimals(balance.amount, '')} BRL`,
    `${currencyWithTwoDecimals(balance.foreignCurrencyAmount, '')} ${balance.foreignCurrency}`,
  ]

  let cells = columnContent.map((content) => (
    <TableCell className="px-0 capitalize" align="left">
      {content}
    </TableCell>
  ))

  return <TableRow>{cells}</TableRow>
}

export default SettlementBatchToApprove
