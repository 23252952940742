import Select from 'react-select'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function FxBankThatApprovedMerchantInput({ fxBanks, item, onChange }) {
  const [request, setRequest] = useState(item)

  const onAnyChanged = (change) => {
    const newRequest = { ...request, ...change }
    setRequest(newRequest)
    onChange(newRequest)
  }

  const onChangeProperty = (property, value) => {
    onAnyChanged({ [property]: value })
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">FX bank:</td>
              <td>
                <Select
                  defaultValue={[]}
                  name="fxBank"
                  className="select-register-input"
                  onChange={(event) => onChangeProperty('fxBank', event.value)}
                  options={fxBanks}
                  classNamePrefix="fxBank"
                  placeholder="FX bank"
                  value={fxBanks.filter((option) => option.value === request.fxBank)}
                  isDisabled={!item.removable}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Submission date:</td>
              <td>
                <input
                  className="account-register-input-text"
                  name="requestedDate"
                  onChange={(event) => onChangeProperty('requestedDate', event.target.value)}
                  step="any"
                  id="requestedDate"
                  type="date"
                  placeholder="Request Date"
                  defaultValue={request.requestedDate}
                  disabled={!item.removable}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Approval date:</td>
              <td>
                <input
                  className="account-register-input-text"
                  name="approvalDate"
                  onChange={(event) => onChangeProperty('approvalDate', event.target.value)}
                  step="any"
                  id="approvalDate"
                  type="date"
                  placeholder="Appoval Date"
                  defaultValue={request.approvalDate}
                  disabled={!item.removable}
                  required
                />
              </td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
